<div *ngIf="bundleView$ | async as bundleView" class="h-full">
  <div class="flex content-between flex-col flex-wrap h-full">
    <div class="{{ bundleView.commercialCode }} grow">
      <div class="header flex items-center">
        <img src="{{ bundleView?.picture }}" alt="{{ bundleView?.commercialCode }}" />
      </div>
      <div class="promo">
        <app-promo *ngIf="bundleView.promo?.title" [promoDetails]="bundleView.promo"></app-promo>
      </div>
      <div class="border-b border-grey"></div>

      <div class="main mt-3">
        <div class="pricing">
          <div class="mb-6 min-h-[7rem] xl:min-h-[3.6rem]" *ngIf="isB2c">
            <div
              *ngIf="bundleView.bundleLinked; else onlyVariableProduct"
              class="flex flex-col items-start xl:items-center xl:flex-row mb-6"
            >
              <span class="font-medium mr-4 mb-4 xl:mb-0"> {{ 'pages.result.products.toggle.label' | translate }}</span>
              <app-te-switcher
                class="inline-block text-sm w-max"
                [options]="[bundleTypes.VARIABLE, bundleTypes.FIX]"
                [active]="bundleView.bundleType"
                [translationPrefix]="'pages.result.products.toggle.'"
                [outlined]="false"
                (switchAction)="onTariffToggled(bundleView)"
              >
              </app-te-switcher>
            </div>

            <ng-template #onlyVariableProduct>
              <div class="flex items-center">
                <!-- SIMU-569 Quick fix only to mid of September 2023, then we need to update bundleCode -->
                <!-- in PC for all products and we will show message for Variable products  -->
                <span
                  class="mr-4 mb-4 xl:mb-0"
                  *ngIf="bundleView?.code !== 'E_MOBILITY_DUAL' && bundleView?.code !== 'E_MOBILITY_ELEC'"
                  >{{ 'pages.result.products.onlyVariableProduct' | translate }}</span
                >
              </div>
            </ng-template>
          </div>

          <div
            class="font-display font-normal inline-block w-full"
            [ngClass]="{ 'te-text-gradient-home': !bundleView.gradient, 'text-gradient': bundleView.gradient }"
            [ngStyle]="{ 'background-image': bundleView.gradient }"
          >
            <span class="text-5xl bold">
              {{ bundleView.totalAmount / 12 | currency: 'EUR' : 'symbol' : '1.2-2' }}
            </span>
            <span class="text-xs"
              >{{ 'pages.result.perMonth' | translate }}
              {{ isB2c ? '' : ('pages.result.withoutVatUnit' | translate) }}</span
            >
          </div>
          <div class="text-xs mb-6 pb-4 border-solid border-b border-grey flex items-center text-grey-dark">
            <span>{{ 'common.price' | translate }}</span>
            <span *ngIf="bundleView.totalPromoAmount > 0" class="line-through flex-1 ml-2 font-medium">
              {{ bundleView.totalAmount + bundleView.totalPromoAmount | currency: 'EUR' : 'symbol' : '1.2-2' }}
            </span>
            <span class="bg-grey-light rounded-full px-4 py-2 font-medium perYear">
              {{ bundleView.totalAmount | currency: 'EUR' : 'symbol' : '1.2-2' }}
              {{ 'pages.result.perYear' | translate }}
            </span>
          </div>
        </div>
        <div
          *ngIf="bundleView?.description && bundleView?.description[translateService.currentLang]"
          class="product-description flex flex-col text-sm"
          [innerHTML]="bundleView.description[translateService.currentLang]"
        ></div>
      </div>
    </div>
    <div class="flex flex-wrap gap-y-8 mt-8">
      <button
        class="btn btn-primary button-become-customer"
        (click)="becomeCustomerClicked.emit(bundleView.bundleType)"
      >
        {{ 'pages.result.becomeCustomerBtn' | translate }}
      </button>
      <button class="btn btn--inline ml-8 color-red" (click)="moreInfo(bundleView.commercialLinks)">
        {{ 'pages.result.moreInfo' | translate }}
      </button>
    </div>
  </div>
</div>
